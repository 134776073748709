import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Galleria</h2>
            {
              /*
<p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p>
              */
            }
            
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/colombe.jpg"
                      title="Le nostre colombe"
                      data-lightbox-gallery="colombe"
                    >
                      <div className="hover-text">
                        <h4>Le nostre colombe</h4>
                      </div>
                      <img
                        src="img/portfolio/colombe.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Le nostre colombe"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/cassata.jpg"
                      title="Cassata"
                      data-lightbox-gallery="cassata"
                    >
                      <div className="hover-text">
                        <h4>Cassata siciliana</h4>
                      </div>
                      <img
                        src="img/portfolio/cassata.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Cassata"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/facciata.jpg"
                      title="La nostra sede"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>La nostra sede</h4>
                      </div>
                      <img
                        src="img/portfolio/facciata.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="La nostra sede"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/frutta_martorana.jpg"
                      title="Frutta martorana"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Frutta martorana</h4>
                      </div>
                      <img
                        src="img/portfolio/frutta_martorana.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Frutta martorana"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/panettone_pistacchio.jpg"
                      title="Panettone al pistacchio"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Panettone al pistacchio</h4>
                      </div>
                      <img
                        src="img/portfolio/panettone_pistacchio.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Panettone al pistacchio"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/torroncino2.jpg"
                      title="Torroncino"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Torroncino</h4>
                      </div>
                      <img
                        src="img/portfolio/torroncino2.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Torroncino"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/tortejpg"
                      title="Torte"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Torte</h4>
                      </div>
                      <img
                        src="img/portfolio/torte.jpg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Torte"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/mignon.jpeg"
                      title="Mignon"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Mignon</h4>
                      </div>
                      <img
                        src="img/portfolio/mignon.jpeg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Mignon"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" style={{height:300}}>
                    {" "}
                    <a
                      href="img/portfolio/cornetti.jpeg"
                      title="Cornetti"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Cornetti</h4>
                      </div>
                      <img
                        src="img/portfolio/cornetti.jpeg"
                        style={{objectFit:'cover',width:'100%',height:'100%'}}
                        alt="Cornetti"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
